<template>
  <div class="banners-content">
    <div class="sticky-top" style="top: 93px;">
      <a-row
        class="d-flex align-items-center"
        :class="{ 'mb-4': !isViewscreen }"
        style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff"
      >
        <a-col :span="10">
          <a-breadcrumb v-if="isViewscreen" class="mb-2">
            <a-breadcrumb-item><a style="color: #1890FF" @click="redirectToMain">Main</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{viewscreenName}}</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)">Banners & Stories</a></a-breadcrumb-item>
          </a-breadcrumb>
          <h3 class="mb-0" style="font-size: 20px">Banners & Stories</h3>
        </a-col>
        <a-col :span="14" class="text-right">
          <a-button v-if="!isViewscreen" type="default" size="large" class="mr-3" @click="openExportModal" :disabled="loading">
            <a-icon type="upload" />
            Export
          </a-button>
          <a-button
            v-if="!isViewscreen"
            type="primary"
            size="large"
            style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="createPromo"
            :disabled="!permissionCreate"
          >
            <a-icon type="plus" /> Create new
          </a-button>
          <a-button
            v-else
            type="primary"
            size="large"
            style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="addPromoToViewscreen"
            :disabled="!promoToViewscreenArray.length"
            :loading="loading"
          >
            Save
          </a-button>
        </a-col>
      </a-row>
      <a-row v-if="isViewscreen" class="d-flex align-items-center mb-4" style="margin: 30px -30px; padding: 13px 24px; background: #FAFAFA">
        <a-col :span="24">
          <div class="d-flex justify-content-end">
            <span v-if="promoToViewscreenArray.length" class="mr-4 text-gray-7">{{ imageCounter }}</span>
            <a-checkbox :checked="isAddAllChecked" @change="handleAddAllPromo">Select All</a-checkbox>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="padding: 24px; background-color: #fff">
      <div class="d-flex align-items-center mb-4">
        <ProductSelect v-model="filters.promoType" class="mr-3" @change="listUpdate"  :disabled="promoLoading" style="width: 220px;"/>
        <AppSelect v-model="filters.applicationId" :apps="apps" class="mr-3"  @change="listUpdate"
                   :disabled="appsLoading"
                   style="width: 220px;"/>
        <LocalizationsSelect v-model="filters.localizationId" :localizations="languagesOptions"
                             :disabled="languagesLoading"
                             class="mr-3" @change="listUpdate"/>
        <ActiveStatus v-model="filters.active"  @change="listUpdate"  :disabled="promoLoading"/>
        <a-input-search
            style="width: 200px; height: 40px; margin-top: auto;"
            size="large"
            v-model="filters.name"
            placeholder="Input search text"
            enter-button
            @search="listUpdate"
            :disabled="promoLoading"
            class="ml-auto"
          />
      </div>
      <draggable
        v-if="promoImages.length"
        v-model="promoImages"
        v-bind="dragOptions"
        @start="drag = true"
        @end="sortPromo()"
      >
        <transition-group>
          <PromoImageCard
            v-for="(promo, index) in promoImages"
            :class="{ 'mt-4': index }"
            :promo-info="promo"
            :permissionEdit="permissionEdit"
            :key="promo.promoId"
            :isPromoChecked="promoToViewscreenArray.includes(promo.promoId)"
            :isViewscreen="isViewscreen"
            @childOpenFunction="openPromoPage(promo.promoType, promo.promoId)"
            @addToViewscreenArray="handleAddToViewscreenArr(promo.promoId)"
            @deleteFromViewscreenArray="handleDeleteViewscreeArr(promo.promoId)"
          />
        </transition-group>
      </draggable>
    </div>
    <a-modal v-model="isExportOpen" title="Export" :footer="null" width="450px" :destroyOnClose="true">
      <ExportPromoModal @close="closeExportModal" />
    </a-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ExportPromoModal from '@/views/apps/promo-images/modals/exportPromo/exportPromoModal.vue'
import AppSelect from '@/components/custom/filters/appSelect.vue'
import ProductSelect from '@/components/custom/filters/productSelect.vue'
import LocalizationsSelect from '@/components/custom/filters/localizationsSelect.vue'
import ActiveStatus from '@/components/custom/filters/activeStatus.vue'
import PromoImageCard from '@/views/apps/promo-images/promoList/promoImageCard.vue'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import ViewscreenService from '@/services/api/apiViewscreenService'
import draggable from 'vuedraggable'
export default {
  name: 'Promo-images',
  mixins: [accessMix],
  components: {
    draggable,
    ExportPromoModal,
    ProductSelect,
    AppSelect,
    LocalizationsSelect,
    ActiveStatus,
    PromoImageCard,
  },
  props: {
    bannerInfo: {
      type: Object,
    },
    banId: {
      type: Number,
    },
    banObj: {
      type: Object,
    },
  },
  data() {
    return {
      promoLoading: false,
      appsLoading: false,
      languagesLoading: false,
      promoImages: [],
      filteredDisplays: [1, 3],
      languages: [],
      displays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      drag: false,
      apps: undefined,
      filters: {
        applicationId: undefined,
        localizationId: undefined,
        promoType: undefined,
        active: true,
        name: undefined,
      },
      pagination: {
        current: 1,
        results: 15,
        total: 0,
      },
      promoToViewscreenArray: [],
      loading: false,
      viewscreenData: null,
      isAddAllChecked: false,
      isExportOpen: false,
    }
  },
  computed: {
    ...mapState(['user']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    languagesOptions() {
      return this.languages.map(function (row) {
        return { value: row.id, label: row.desc }
      })
    },
    permissionCreate() {
      return this.checkPermissions(this.user.permissions, 'banners', 'create') && this.checkPermissions(this.user.permissions, 'stories', 'create')
    },
    permissionEdit() {
      return this.checkPermissions(this.user.permissions, 'banners', 'edit')
    },
    isViewscreen() {
      return !!this.$route.params.sectionId
    },
    viewscreenName() {
      if (!this.viewscreenData) {
        return null
      }
      return this.viewscreenData.name
    },
    imageCounter () {
      return `${this.promoToViewscreenArray.length} ${this.promoToViewscreenArray.length === 1 ? 'image' : 'images'} selected`
    },
  },
  async created() {
    await this.fetchApplications()
    await this.fetchLocalizations()
    await this.listUpdate()
    if (this.$route.params.viewscreenId) {
      await this.getViewscreenTab(this.$route.params.viewscreenId, '0')
    }
  },
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
    promoImages() {
      this.promoToViewscreenArray = []
      this.isAddAllChecked = false
    },
  },
  methods: {
    openExportModal() {
      this.isExportOpen = true
    },
    closeExportModal() {
      this.isExportOpen = false
    },
    handleAddToViewscreenArr(promoId) {
      this.promoToViewscreenArray.push(promoId)
      if (this.promoToViewscreenArray.length === this.promoImages.length) {
        this.isAddAllChecked = true
      }
    },
    handleDeleteViewscreeArr(promoId) {
      const deletedIndex = this.promoToViewscreenArray.findIndex(item => item === promoId)
      if (deletedIndex !== -1) {
        this.promoToViewscreenArray.splice(deletedIndex, 1)
      }
      this.isAddAllChecked = false
    },
    handleAddAllPromo() {
      if (!this.isAddAllChecked) {
        const nonDuplicateIds = this.promoImages.map(promo => promo.promoId).filter(promoId => !this.promoToViewscreenArray.includes(promoId))
        this.promoToViewscreenArray.push(...nonDuplicateIds)
        this.isAddAllChecked = true
      } else {
        this.promoToViewscreenArray = []
        this.isAddAllChecked = false
      }
    },
    openPromoPage(promoType, promoId) {
      const url = this.$router.resolve({ path: `/promo-images/edit-promo/${promoType}/${promoId}` }).href
      window.open(url, '_blank')
    },
    redirectToMain() {
      this.$router.push(`/main/${this.$route.params.viewscreenId}`)
    },
    async listUpdate() {
      try {
        this.promoLoading = true
        await this.fetchPromo()
      } catch (e) {
        console.error(e)
      } finally {
        this.promoLoading = false
      }
    },
    async addPromoToViewscreen() {
      try {
        this.loading = true
        const promoPayload = {
          item_ids: this.promoToViewscreenArray,
        }
        const addPromoResponse = await ViewscreenService.addMultipleSectionItems(this.$route.params.sectionId, promoPayload)
        if (addPromoResponse.data.data) {
          this.$notification.success({
            message: 'Promo successfully added to viewscreen',
          })
          this.redirectToMain()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async fetchPromo() {
      const { active, promoType, localizationId, applicationId, name } = this.filters
      const searchParams = new URLSearchParams()
      searchParams.append('active', active)

      if (typeof promoType !== 'undefined') {
        searchParams.append('promoType', promoType)
      }

      if (typeof localizationId !== 'undefined') {
        searchParams.append('lang_id', localizationId)
      }

      if (typeof applicationId !== 'undefined') {
        searchParams.append('app_id', applicationId)
      }
      if (typeof name !== 'undefined') {
        searchParams.append('name', name)
      }

      if (this.isViewscreen) {
        searchParams.append('viewscreen_section_id', this.$route.params.sectionId)
      }

      const response = await apiClient.get(`admin/promo?${searchParams}`)
      this.promoImages = response.data.data
    },
    createPromo() {
      this.$router.push('/promo-images/create-promo')
    },
    async fetchApplications() {
      this.appsLoading = true
      try {
        const url = '/admin/applications'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.apps = response.data.data
          this.apps.forEach(item => {
            if (item.is_default === 1) {
              this.filters.applicationId = item.application_id
            }
          })
        }
      } finally {
        this.appsLoading = false
      }
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async sortPromo() {
      const promoSort = this.promoImages.map((promo, index, promoArr) => {
        return {
          promoId: promo.promoId,
          promoType: promo.promoType,
          sort: promoArr.length - index,
        }
      })
      const sort = { sort: promoSort }
      try {
        await apiClient.post(
          `/admin/promo/sort/${this.filters.applicationId}`,
          sort,
        )
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
    async getViewscreenTab(id, langId) {
      this.loading = true
      try {
        const response = await ViewscreenService.getViewscreenTab(id, langId)
        if (response.data.data) {
          this.viewscreenData = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style>
/*.ant-modal { width: 1000px !important; }*/
.ant-modal-title {
  font-weight: bold !important;
  font-size: 18px !important;
}
.banners-active-filter-switch {
  /*border: 1px solid #000;*/
  display: inline-block !important;
  padding: 0 0.5rem 0 0.5rem;
  line-height: 38px;
  width: auto;
}
.banners-loading-spinner {
  /*width: 64px;*/
  /*height: 64px;*/
  border-radius: 2px;
  padding: 1rem;
  font-size: 96px;
  /*background: rgba(0, 0, 0, 0.1);*/
  margin-top: 6rem;
  position: absolute;
  margin-left: calc(50% - 40px);
  z-index: 999999;
}
.select-filter-localization {
  max-height: 38px !important;
  width: 100%;
}
.filter-drawer .ant-drawer-content-wrapper,
.filter-drawer .ant-drawer-content,
.filter-drawer .ant-drawer-wrapper-body {
  overflow: initial;
}
/* @media screen and (min-width: 1024px) {
      .filter-drawer .ant-drawer-wrapper-body {
        overflow-y: hidden;
      }
    } */
</style>
<style scoped>
.banner-slider::before {
  display: none;
}
.banner-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, 343px);
  grid-gap: 32px 57px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 24px 0;
  background: #fff;
  border-radius: 2px;
}
.banner-modal {
  width: 1000px !important;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 343px);
  grid-gap: 32px 57px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 24px 0;
  background: #fff;
  border-radius: 2px;
}
.flip-grid-move {
  transition: transform 0.5s;
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

@media screen and (max-width: 1473px) {
  .banner-slider {
    grid-template-columns: repeat(3, 250px);
  }
  .banner-slider .flex-column {
    width: 100px !important;
  }
}
@media screen and (max-width: 1200px) {
  .banner-slider {
    grid-template-columns: repeat(auto-fit, 250px);
  }
}
</style>
