<template>
    <span class="d-inline-flex align-items-center py-1 px-2 font-bold verification-status"
    :class="{
      'finished-status': status === 'FINISHED',
      'transferred-status': status === 'TRANSFERRED',
      'pending-status': status === 'PENDING',
      'canceled-status': status === 'CANCELED'
    }">{{ computedTitle }}</span>
</template>
<script>

export default {
  name: 'verificationStatus',
  props: {
    status: {
      type: String,
      default: 'Pending',
    },
  },
  data: () => ({}),
  computed: {
    computedTitle() {
      return this.status.charAt(0) + this.status.slice(1).toLowerCase()
    },
  },
}
</script>
<style lang="scss">
.verification-status {
    border-radius: 120px;
}
.finished-status {
    background-color: #eaf9f0;
    color: #32bf61
}
.transferred-status {
  background-color: #fff8e1;
  color: #ffab00;
}
.pending-status {
    background-color: #e3f2fd;
    color: #007bff
}
.canceled-status {
    background-color: #FFECEB;
    color: #FF3B30;
}
</style>
