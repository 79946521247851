<template>
    <a-form-model ref="verificationModal" :model="form" :rules="rules" class="verification-modal">
        <a-form-model-item ref="amount" label="Amount" prop="amount">
            <a-input-number v-model="form.amount" :disabled="loading" size="large" placeholder="Enter the amount to be paid." style="width: 100%;" />
        </a-form-model-item>
        <hr>
        <a-row>
            <a-col class="d-flex justify-content-between" :span="24">
                <a-popconfirm placement="top" title="Are you sure you want to decline?" @confirm="declinePayment">
                    <a-button type="danger" :loading="loading">
                        Decline
                    </a-button>
                </a-popconfirm>
                <a-popconfirm placement="left" title="Are you sure you want to confirm?" @confirm="onSubmit">
                    <a-button type="primary" :loading="loading">
                        Confirm
                    </a-button>
                </a-popconfirm>
            </a-col>
        </a-row>
    </a-form-model>
</template>

<script>
import PrismaService from '@/services/api/apiPrismaService'

export default {
  name: 'VerificationModal',
  props: ['prizeId', 'amount', 'email', 'permissionToConfirm'],
  components: {
  },
  data() {
    return {
      form: {
        amount: null,
      },
      loading: false,
      rules: {
        name: [{ required: true, message: 'Please input the amount to be paid', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.form.amount = this.amount
  },
  methods: {
    closeVerificationModal() {
      this.$emit('close')
    },
    emitConfirm() {
      this.$emit('confirm')
    },
    async onSubmit() {
      const isValid = await new Promise((resolve) => {
        this.$refs.verificationModal.validate(resolve)
      })
      if (!isValid) {
        return false
      }
      return await this.confirmPayment()
    },
    async confirmPayment() {
      this.loading = true

      const confirmationPayload = {
        sender: this.email,
        amount: this.form.amount,
      }
      if (!this.permissionToConfirm) {
        this.$notification.error({ message: "You don't have permission for this action!" })
        this.closeVerificationModal()
        return
      }
      try {
        const confirmationResponse = await PrismaService.transferPrize(this.prizeId, confirmationPayload)

        if (confirmationResponse.data) {
          this.$notification.success({
            message: 'Payment successfully confirmed',
          })
          this.emitConfirm()
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.error.message,
        })
      } finally {
        this.loading = false
      }
    },
    async declinePayment() {
      this.loading = true
      const cancelPayload = {
        sender: this.email,
      }
      if (!this.permissionToConfirm) {
        this.$notification.error({ message: "You don't have permission for this action!" })
        this.closeVerificationModal()
        return
      }
      try {
        const confirmationResponse = await PrismaService.cancelPrize(this.prizeId, cancelPayload)
        if (confirmationResponse.data) {
          this.$notification.success({
            message: 'Payment successfully canceled',
          })
          this.emitConfirm()
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.data.message,
        })
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang="scss"></style>
