<template>
  <div class="universal-select">
    <span v-if="title" class="universal-select__title">{{ title }}</span>
    <a-select
      :allow-clear="allowClear"
      :value="value"
      size="large"
      @change="handleChange"
      :disabled="disabled"
      :loading="loading"
      :get-popup-container="getPopupContainer"
    >
      <template v-if="placeholder" #placeholder>
        <span style="color: #a1a1c2;">{{ placeholder }}</span>
      </template>
      <a-select-option
        v-for="(option, index) in options"
        :key="option.value"
        class="universal-select__option"
        :class="{'universal-select__option-highlight' : index === 0 && value !== option.value && highlightFirst}"
      >
        <div class="universal-select__option__content">
          <div v-if="option?.platform" class="universal-select__option__content-icon">
            <a-icon v-if="option.platform.toLowerCase() === 'android'" type="android" class="text-success" />
            <a-icon v-if="option.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
            <a-icon v-if="option.platform.toLowerCase() === 'web'" type="global" class="text-default" />
          </div>
          {{ option.name }}
        </div>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'universalSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    highlightFirst: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: undefined,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    displayOptionsAtBottom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPopupContainer(trigger) {
      if (this.displayOptionsAtBottom) {
        return document.body
      }
      return trigger.parentElement
    },
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss">
.universal-select {
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: bold;
  }
  &__option {
    &-highlight {
      font-style: italic !important;
    }
    &__content {
      display: flex;
      gap: 8px;
    }
    &__content-icon {
      display: inline;
    }
  }
}
</style>
